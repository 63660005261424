import { truncate, dateFormater } from '@/filters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImagesItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    image: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapGetters('image', ['selectedImagesId']),
    isSelected () {
      return this.selectedImagesId.includes(this.image.id)
    }
  },
  methods: {
    ...mapActions('image', ['setSelectedImagesIdAction']),
    handleDeleteImage () {
      this.$emit('deleteImage', this.image.id)
    },
    handleSelectImage () {
      this.setSelectedImagesIdAction(this.image.id)
    }
  }
}
